<template>
  <div class="randje">
      <div class="background">
        <div class="selection">
            <img src="https://www.fijn.com/-/media/project/van-wijnen-groep/fijn-holding/websites/fijn-corporate/wonen/logos/fijn-wonen.svg" style="width:100px">
            <!-- <p style="font-size: 26; font-weight: bold; float: right; margin-top: 55px;">Fijn Wonen 2.1</p> -->
            <h1>Technische Omschrijving</h1>
            <p>Jaar
              <select v-model="configdata.jaar">
                <!-- <option>2021</option> -->
                <option>2020</option>
                <option>>=2021</option>
              </select>
            </p>
            <p>Woningype
              <select v-model="configdata.woningtype">
                <!-- <option>2021</option> -->
                <option>101</option>
                <option>200</option>
                <option>201</option>
                <option>202</option>
                <option>300</option>
                <option>301</option>
                <option>302</option>
                <option>101llb</option>
              </select>
            </p>
            <p>Doelgroep
              <select v-model="configdata.doelgroep">
                <!-- <option>2021</option> -->
                <option>Sociale huur</option>
                <option>Middeldure huur</option>
                <option>Belegger</option>
                <option>Koop laag</option>
                <option>Koop middel-duur</option>
              </select>
            </p>
            <p>Energievariant
              <select v-model="configdata.energievariant">
                <option v-if="configdata.jaar == '2020'">epc0,4</option>
                <option v-if="configdata.jaar == '2020'">epc0,0</option>
                <option v-if="configdata.jaar == '>=2021'">ENG</option>
                <option v-if="configdata.jaar == '>=2021'">BENG</option>
                <option>NOM</option>
                <option>NOM (PG)</option>
              </select>
            </p>
            <h2>Features</h2>
            <p>Onderhoudsarme Berging
              <select v-model="configdata.onderhoudsarmeberging">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="['201','300','301','302'].includes(configdata.woningtype)">Zolderindeling
              <select v-model="configdata.zolderindeling">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="configdata.zolderingeling == 'Ja'">Binnenwand Zolder
              <select v-model="configdata.binnenwandzolder">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Garage
              <select v-model="configdata.garage">
                <option>Geen</option>
                <option>Standaard met Kanteldeur</option>
                <option>Loopdeur in kanteldeur</option>
                <option>Sectionaaldeur</option>
                <option>Dubbele deuren</option>
              </select>
            </p>
            <p v-if="configdata.garage !== 'Geen'">Lichtkoepel garage
              <select v-model="configdata.lichtkoepel">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p>Fundering
              <select v-model="configdata.fundering">
                <option>Standaard betonnen palen</option>
                <option>Mortel Schroefpalen 350mm</option>
              </select>
            </p>
            <p v-if="['101','101llb','201','301'].includes(configdata.woningtype)">Extra kantelraam
              <select v-model="configdata.kantelraam">
                <option>Nee</option>
                <option v-if="['101','101llb'].includes(configdata.woningtype)">Wit kantelraam</option>
                <option v-if="['201','301'].includes(configdata.woningtype)">Wit kantelraam zolder voorzijde</option>
                <option v-if="['201','301'].includes(configdata.woningtype)">Wit kantelraam zolder achterzijde</option>
                <option v-if="['201','301'].includes(configdata.woningtype)">Wit kantelraam zolder voor en achter</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Trapkast
              <select v-model="configdata.trapkast">
                <option>Geen</option>
                <option>Klein</option>
                <option>Groot</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Uitbouw
              <select v-model="configdata.uitbouw">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="['101','101llb','201','301'].includes(configdata.woningtype)">Keramische Dakpannen
              <select v-model="configdata.keramischedakpannen">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="['101llb','200','202','300','302'].includes(configdata.woningtype)">Dakopstand
              <select v-model="configdata.dakopstand">
                <option>Standaard</option>
                <option>Blank geanodiseerde kraaltrim</option>
                <option v-if="configdata.woningtype !== '101llb'">Kunststof dakrand met overstek</option>
              </select>
            </p>
            <p v-if="['200','201','202','300','301','302'].includes(configdata.woningtype)">Matglas badkamer
              <select v-model="configdata.matglasbadkamer">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p>Veiligheidsglas
              <select v-model="configdata.veiligheidsglas">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p>Glasroeden voorgevel
              <select v-model="configdata.glasroeden">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
        <!-- </div>
        <div class="selection"> -->
            <p>Antraciet vensterbanken
              <select v-model="configdata.antracietvensterbanken">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Vloerplinten
              <select v-model="configdata.vloerplinten">
                <option>Geen</option>
                <option>vloerplinten 9x45mm blank</option>
                <option>vloerplinten 12x56mm wit</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Folie badkamer
              <select v-model="configdata.foliebadkamer">
                <option>Geen</option>
                <option>Taupe</option>
                <option>Betonlook</option>
                <option>Bruin hout</option>
                <option>Licht grijze houtlook</option>
                <option>Canvas</option>
              </select>
            </p>
            <p>Keuken
              <select v-model="configdata.keuken">
                <option>Standaard</option>
                <option>Geen</option>
                <option>Hoekkeuken</option>
                <option>Parallel keukenopstelling</option>
              </select>
            </p>
            <p>Sparing afvoer afzuigkap
              <select v-model="configdata.sparingafzuigkap">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="['101','201','301'].includes(configdata.woningtype)">Dakgoten
              <select v-model="configdata.dakgoten">
                <option>Standaard</option>
                <option>Zinklook voorgevel</option>
                <option>Zinklook achtergevel</option>
                <option>Zinklook beide gevels</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Waterontharder
              <select v-model="configdata.waterontharder">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="['200','201','202','300','301','302'].includes(configdata.woningtype)">Sanitairpakket
              <select v-model="configdata.sanitairpakket">
                <option>Comfort</option>
                <option>Luxe 1</option>
                <option>Luxe 2</option>
                <option>Excellent</option>
              </select>
            </p>
            <p v-if="['200','201','202','300','301','302'].includes(configdata.woningtype)">Douchewand
              <select v-model="configdata.douchewand">
                <option>Geen</option>
                <option>Glazen douchewand zonder deur</option>
                <option>Glazen douchewand met deur</option>
              </select>
            </p>
            <p v-if="['200','201','202','300','301','302'].includes(configdata.woningtype)">Ligbad
              <select v-model="configdata.ligbad">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="['200','201','202','300','301','302'].includes(configdata.woningtype)">Toilet in badkamer
              <select v-model="configdata.tweedetoilet">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="['201','300','301','302'].includes(configdata.woningtype)">Elek. radiator tweede verdieping
              <select v-model="configdata.radiatorzolder">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Spatwaterdichte wandcontactdoos
              <select v-model="configdata.wandcontactdoos">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Grondkabel
              <select v-model="configdata.grondkabel">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Dimbaar licht eettafel
              <select v-model="configdata.dimbaarlicht">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Buitenlichtpunt voordeur
              <select v-model="configdata.buitenlichtpunt">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Spotjes badkamer en toilet
              <select v-model="configdata.spotjesbadkamer">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
            <p v-if="configdata.woningtype !== '101llb'">Afmontage databekabeling
              <select v-model="configdata.afmontagedata">
                <option>Nee</option>
                <option>Ja</option>
              </select>
            </p>
        </div>
        <div class="eto">
          <h2>ETO's</h2>
          <textarea v-model="configdata.eto" rows="7" cols="70"></textarea>
          <button type="submit" @click="generate()">Download</button>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data: function() {
        return {
            configdata: {
                jaar: '2020',
                woningtype: '101',
                doelgroep: 'Sociale huur',
                energievariant: 'NOM (PG)',
                onderhoudsarmeberging: 'Nee',
                uitbouw: 'Nee',
                garage: 'Geen',
                lichtkoepel: 'Nee',
                fundering: 'Standaard betonnen palen',
                kantelraam: 'Nee',
                zolderindeling: 'Nee',
                binnenwandzolder: 'Nee',
                trapkast: 'Geen',
                keramischedakpannen: 'Nee',
                dakopstand: 'Standaard',
                matglasbadkamer: 'Nee',
                veiligheidsglas: 'Nee',
                glasroeden: 'Nee',
                antracietvensterbanken: 'Nee',
                vloerplinten: 'Geen',
                foliebadkamer: 'Geen',
                keuken: 'Standaard',
                sparingafzuigkap: 'Nee',
                dakgoten: 'Standaard',
                waterontharder: 'Nee',
                sanitairpakket: 'Comfort',
                douchewand: 'Geen',
                ligbad: 'Nee',
                tweedetoilet: 'Nee',
                radiatorzolder: 'Nee',
                wandcontactdoos: 'Nee',
                grondkabel: 'Nee',
                dimbaarlicht: 'Nee',
                buitenlichtpunt: 'Nee',
                spotjesbadkamer: 'Nee',
                afmontagedata: 'Nee',
                eto: '',
            },
        }
    },
    methods: {
      generate() {
        axios({
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            url: '/api/generate',
            data: this.configdata,
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'to.docx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type=text], select {
    width: 100%;
    padding: 2px 2px;
    margin: 0px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  /* option {
      background-color: #F2DCDD;
  } */
  
  button[type=submit] {
    width: 100%;
    background-color: #75C4B7;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 3rem;
    cursor: pointer;
  }
  
  button[type=submit]:hover {
    background-color: #A6CE84;
  }
  
div.background{
    background-color: #f2f2f2;
    padding: 20px;
    max-width: 660px;
}

div.eto {
  /* width: 600px; */
  /* height: 150px; */
  /* float: left; */
  margin: 5px;
  /* float: left; */
}

div.selection {
    /* border-radius: 2rem; */
    background-color: #f2f2f2;
    padding: 20px;
    /* width: 610px; */
    float: left;
    /* height: 1100px; */
    /* max-height: 1000px; */
    /* position: absolute; */
    /* top: 30%; */
    /* left: 50%; */
    /* -ms-transform: translate(-50%, -50%); */
    /* transform: translate(-50%, -50%); */
    /* border-bottom: 3px solid #ecebeb; */
  }

  p{
      font-family: Arial;
      width: 280px;
      float:left;
      margin: 5px 10px;
      height: 40px;
  }

  h1, h2, h3 {
      font-family: Bitter;
  }

  div.randje {
      /* border-radius: 2rem; */
      background-color: #F2DCDD;
      padding: 13px;
      max-width: 700px;
      /* width: 700px; */
      /* max-height: 1200px; */
      position: absolute;
      /* top: 40%; */
      left: 50%;
      -ms-transform: translate(-50%, 0%);
      transform: translate(-50%, 0%);
      /* border-bottom: 3px solid #ecebeb; */
  }
</style>
